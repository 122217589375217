import React, { Component } from 'react';


class MailSVG extends Component {
	render(){
		return(
			<a href={this.props.link}>
				<div className="svg-container mailto">
					<svg viewBox="0 0 28 20" >
					    <path d="M2.10989011,0.336633663 C1.71356703,0.336633663 1.34732418,0.450281188 1.0347011,0.641724752 L12.9896978,10.6670859 C13.6284341,11.2027711 14.4150846,11.2027374 15.0549451,10.6670859 L27.031233,0.641724752 C26.7186099,0.450281188 26.352367,0.336633663 25.956044,0.336633663 L2.10989011,0.336633663 L2.10989011,0.336633663 Z M0.183052747,1.69370495 C0.110151648,1.90171089 0.0659340659,2.12331683 0.0659340659,2.35643564 L0.0659340659,17.1683236 C0.0659340659,18.2872939 0.977538462,19.1881255 2.10989011,19.1881255 L25.956044,19.1881255 C27.0883956,19.1881255 28,18.2872939 28,17.1683236 L28,2.35643564 C28,2.12331683 27.9557824,1.90171089 27.8828813,1.69370495 L15.9385473,11.6980265 C14.8307571,12.625385 13.2135791,12.6268661 12.1061297,11.6980265 L0.183052747,1.69370495 L0.183052747,1.69370495 Z" id="evelope_svg"></path>
					</svg>
				</div>
			</a>
		);
	}
}

export default MailSVG;